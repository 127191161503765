<template>
    <div class="DialogReportPaid__Component">
        <NewActivity ref="taskActivity" v-if="isModalOpen" type="Task" :propPriority="taskReminderObj.Priority"
            :propTitle="getTitle" :propReminder="getReminder" :propAssignedUserId="taskReminderObj.AssignedUserId"
            show-title="true" @onClose="onTaskModelClose" @onSave="saveTask" :propBody="taskReminderObj.Body" :propisAdminWithoutProfileId="isAdminWithoutProfileId" />
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת משימה/הערה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field v-if="reportNumbers" label="הזן מספרי דוח">
                        <b-taginput v-model="reportNumbersTag" :loading="loading" :data="filteredTags" field="ReportNumber"
                            icon="label" :allow-new="false" autocomplete placeholder="הזן מספר דוח"
                            aria-close-label="Delete this tag" @typing="getFilteredTags"></b-taginput>
                    </b-field>
                    <p class="content" v-if="reportNumbers && reportNumbersTag.length"><b>דוחות:</b> {{
                        reportNumbersTag.map((report) => report.ReportNumber) }}</p>
                    <b-field label="הערה">
                        <b-input v-model="reference"></b-input>
                    </b-field>
                    <div class="activity-container">
                        <b-checkbox :loading="isLoading" v-model="isCreateTask" label="" type="is-link is-light"
                            class="checkbox" @click.native="onIsCreateTask">
                            צור משימה
                        </b-checkbox>
                        <a @click="
                            isCreateTask = true;
                        isModalOpen = true;
                        ">ערוך</a>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import EntityItemsService from "@/services/EntityItemsService";
import { ToastProgrammatic as Toast } from "buefy";
import ReportService from "@/services/ReportService";
import NewActivity from "./NewActivity.vue";

export default {
    name: "DialogReportDriverAssign",
    props: ["reportNumbers", "reports", "onSuccess", "store"],
    components: {
        NewActivity,
    },
    computed: {
        isStatusValid() {
            if (this.submitted) {
                return this.statusId !== undefined;
            }
            return true;
        },
        isAdminWithoutProfileId() {
            return !this.store.state.auth.profileId && this.store.state.auth?.user?.claims?.isAdmin === "True";
        },
        getTitle() {
            return '';
            // console.log(this.reports, this.data);
            // const opt = this.data.find((res) => res.Value === this.statusId);
            // const str = this.reportsNumber;
            // return `סטטוס שונה ל${opt.Text}, עבור דוחות: ${str}`;
        },
        getReminder() {
            const date = new Date();
            return date.setDate(date.getDate() + 1);
        },
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            statusId: null,
            reference: null,
            reportNumbersTag: [],
            filteredTags: this.data,
            data: [],
            isCreateTask: false,
            isModalOpen: false,
            taskReminderObj: {
                Body: null,
                AssignedUserId: "",
                Priority: 1,
            },
        };
    },
    methods: {
        saveTask(res) {
            this.isModalOpen = false;
            this.isTask = true;
            this.taskReminderObj = { ...res };
            this.taskReminderObj.DueDate = new Date(this.taskReminderObj.DueDate);
            this.taskReminderObj.EmailReminderDate = new Date(
                this.taskReminderObj.EmailReminderDate
            );
        },
        getFilteredTags(text) {
            if (text.length > 4) {
                this.loading = true;
                ReportService.adminSearch({
                    ReportNumber: text,
                    PageSize: 20,
                    IsAscending: false,
                    ResultType: 3,
                    SortedBy: "LastPayDate",
                    PageNumber: 1
                }).then((res) => {
                    this.filteredTags = res.data.Items;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        setSelected(value) {
            this.statusId = value;
        },
        save() {
            this.submitted = true;
            if (this.isStatusValid && (this.reference || this.isCreateTask)) {
                this.isLoading = true;
                if (this.reference) {
                    let reqs;
                    this.reportNumbersTag.length ? reqs = this.reportNumbersTag.map((r) =>
                        EntityItemsService.addNote(
                            "Report",
                            r.Id,
                            { Body: this.reference }
                        )
                    ) :
                        reqs = this.reports.map((r) =>
                            EntityItemsService.addNote(
                                "Report",
                                r.Id,
                                { Body: this.reference }
                            )
                        );
                    Promise.all(reqs)
                        .then(() => {
                            // this.reports.map((t, idx) => (
                            //     t.activities.unshift(r[idx].data)
                            // ));
                            this.$emit("close");
                            if (this.onSuccess) this.onSuccess();
                            Toast.open({
                                type: "is-success",
                                message: "הפעולה בוצעה!",
                                duration: 4000,
                            });
                        }).catch(() => {
                            Toast.open({
                                type: "is-danger",
                                message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
                if (this.isCreateTask) {
                    let reqs2;
                    this.reportNumbersTag.length ? reqs2 = this.reportNumbersTag.map((report) =>
                        EntityItemsService.addTask(
                            "Report",
                            report.Id,
                            this.taskReminderObj
                        )
                    ) :
                        reqs2 = this.reports.map((report) =>
                            EntityItemsService.addTask(
                                "Report",
                                report.Id,
                                this.taskReminderObj
                            )
                        );
                    Promise.all(reqs2)
                        .then(() => {
                            // this.reports.map((t, idx) => (
                            //     t.activities.unshift(r[idx].data)
                            // ));
                            this.$emit("close");
                            if (this.onSuccess) this.onSuccess();
                            Toast.open({
                                type: "is-success",
                                message: "הפעולה בוצעה!",
                                duration: 4000,
                            });
                        }).catch(() => {
                            Toast.open({
                                type: "is-danger",
                                message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            }
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    },
};
</script>

<style lang="scss"></style>
